/* Base Styles */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

a {
  text-decoration: none;
  color: #007BFF;
  transition: color 0.3s;
}

a:hover {
  color: #0056b3;
}

button {
  cursor: pointer;
}

/* App Styles */
.App {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Header Styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #333;
}

#logo {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

nav a {
  margin: 0 0.5rem;
  color: #fff;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #007BFF;
  color: #fff;
  border-radius: 3px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Hero Section Styles */
#hero {
  padding: 2rem;
  text-align: center;
}

#hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.demo-video {
  margin-top: 2rem;
}

/* Features Section Styles */
#features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem;
}

.feature-item {
  flex-basis: calc(50% - 1rem);
  margin-bottom: 2rem;
}

.feature-item h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Testimonials Styles */
#testimonials {
  padding: 2rem;
  background-color: #f8f9fa;
  text-align: center;
}

/* Pricing Section Styles */
#pricing {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.pricing-item {
  flex-basis: calc(33.33% - 1rem);
  padding: 1rem;
  border: 1px solid #ddd;
  text-align: center;
}

.pricing-item h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Newsletter Section Styles */
#newsletter {
  padding: 2rem;
  background-color: #f8f9fa;
  text-align: center;
}

#newsletter input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-right: 1rem;
}

/* Footer Styles */
footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: #333;
  color: #fff;
}

#quick-links, #social-media, #contact-info, #legal-links {
  flex-basis: calc(25% - 1rem);
}

#legal-links a {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'GarnettRegular';
  src: url('../public/fonts/garnett-regular.woff2') format('woff2'), /* Modern Browsers */
       url('../public/fonts/GarnettRegular.ttf') format('truetype'); /* Older browsers */
}

@font-face {
  font-family: 'GarnettMedium';
  src: url('../public/fonts/garnett-medium.woff2') format('woff2'), /* Modern Browsers */
       url('../public/fonts/GarnettMedium.ttf') format('truetype'); /* Older browsers */
}

p, h1, h2, h3, h4, h5, body, a, div {
  font-family: 'GarnettRegular', Inter, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system; /* Fallback to sans-serif if the custom font fails to load */
}

code {
  font-family: Inter, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system;
}
